















































import Vue from 'vue';
import {Backend} from "api-frontend";
import {User} from 'mp-common/src/types/entities/user';
import {assignmentCoordination} from "mp-common/src/types/api/assignmentCoordinationApi";
import {
  AssignmentCoordinationDate, AssignmentCoordinationTemplate,
} from "mp-common/src/types/entities/assignment-coordination-template";
import {AssignmentCoordinationAnswers} from "mp-common/src/types/entities/assignment-coordination-answers";
import {formatDate, formatTime} from "@/helpers/format-date-time";
import {LocalDate, LocalTime} from "js-joda";

export default Vue.extend({
  name: 'AssignmentCoordinationAnswer',
  props: {
    user: Object as () => User,
    backend: Backend
  },
  data() {
    return {
      template: undefined as AssignmentCoordinationTemplate | undefined,
      answers: undefined as AssignmentCoordinationAnswers["selections"] | undefined,
      sortDates: {
        sortBy: 'date',
        mode: 'asc'
      },
      sortTimes: {
        sortBy: 'time',
        mode: 'asc'
      },
      showMesses: false,
      currentDate: {} as AssignmentCoordinationDate,
      currentDateIndex: 0,
      userId: undefined as undefined | number
    }
  },
  created: async function () {
    await this.fetchCoordination()
  },
  methods: {
    fetchCoordination: async function () {
      const id = this.$route.query.id;
      const userId = this.$route.query.userId;
      const fetchAssignment = async () => {
        if (!userId) {
          return await this.backend.query(assignmentCoordination.getById, {id: Number(id)});
        } else {
          this.userId = Number(userId);
          return await this.backend.query(assignmentCoordination.getForUserById, {
            userId: this.userId,
            templateId: Number(id)
          })
        }
      }
      const result = await fetchAssignment();
      this.template = result?.template;
      this.answers = result?.answers;
    },
    customSortDates(value: any[]) {
      return value.sort((a, b) => {
        const sortBy = this.sortDates.sortBy;

        if (this.sortDates.mode === 'desc') {
          if (a[sortBy] instanceof LocalDate) {
            return b[sortBy].compareTo(a[sortBy]);
          }
          return b[sortBy].localeCompare(a[sortBy])
        }
        if (a[sortBy] instanceof LocalDate) {
          return a[sortBy].compareTo(b[sortBy]);
        }
        return a[sortBy].localeCompare(b[sortBy])
      })
    },
    customSortTimes(value: any[]) {
      return value.sort((a, b) => {
        const sortBy = this.sortTimes.sortBy;

        if (this.sortTimes.mode === 'desc') {
          if (a[sortBy] instanceof LocalTime) {
            return b[sortBy].compareTo(a[sortBy]);
          }
          return b[sortBy].localeCompare(a[sortBy])
        }
        if (a[sortBy] instanceof LocalTime) {
          return a[sortBy].compareTo(b[sortBy]);
        }
        return a[sortBy].localeCompare(b[sortBy])
      })
    },
    formatDate: (date: LocalDate) => formatDate(date),
    formatTime: (time: LocalTime) => formatTime(time),
    openMessesDialog: function (date: AssignmentCoordinationDate) {
      if (this.template) {
        this.currentDate = date;
        this.currentDateIndex = this.template.assignmentDates.indexOf(date);
        this.showMesses = true;
      }
    },
    handleChange: async function () {
      if (this.answers && this.template) {
        if (!this.userId) {
          await this.backend.query(assignmentCoordination.updateAnswersById, {id: this.template.id, answers: this.answers});
        } else {
          await this.backend.query(assignmentCoordination.updateAnswersForUser, {userId: this.userId, templateId: this.template.id, answers: this.answers});
        }
      }
    },
  }
})
